<template>
  <vuestic-widget :loading="loadingAutomation" class="automation-item-page">
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <span class="title">Automation</span>
        <div class="d-flex justify-content-end align-items-center">
        </div>
      </div>
    </template>
    <div v-if="automation">
      <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="automationForm">
        <form @submit.prevent="handleSubmit(submitAutomation)">
          <div class="row">
            <div class="col-md-6">
              <text-input name="Name" v-model="automation.name" label="Name" validate="required"
                tooltip="Name of the Automation" />
              <textarea-input v-if="isTemplate" max-length="300" name="Description" v-model="automation.desc"
                label="Description" />
            </div>
            <div class="col-md-6 d-flex justify-content-end align-items-center">
              <div class="">
                <vuestic-switch v-model="automation.active" :offcolor="true">
                  <span slot="trueTitle">Active</span>
                  <span slot="falseTitle">Inactive</span>
                </vuestic-switch>
              </div>
              <div class="text-right ml-4">
                <b-button class="mr-2" variant="primary" :disabled="loadingSave" type="submit">
                  <b-spinner v-if="loadingSave" small />
                  <span v-else>Save</span>
                </b-button>
                <b-button class="" variant="primary" @click="onClickBack">
                  <span>Back</span>
                </b-button>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <div class="automations-page" :style="{ '--modal-scale': 1 / editorScale }">
        <div class="automation-editor">
          <div class="edit-header">
            <div class="zoom-containter">
              <button class="btn btn-sm btn-primary" @click="zoomOut">
                <i class="fa fa-search-minus"></i>
              </button>
              <button class="btn btn-sm btn-primary ml-2" @click="zoomIn">
                <i class="fa fa-search-plus"></i>
              </button>
            </div>
            <!-- <div class="btn-container">
              <button class="btn btn-primary ml-2">
                Test Automation <i class="fa fa-play-circle"></i>
              </button>
            </div> -->
          </div>
          <Editor :automation="automation" :type="type" :scale="editorScale" />
        </div>
      </div>
    </div>
  </vuestic-widget>
</template>

<script>
import _ from 'lodash';
import Editor from './Components/Editor'
import TagSelector from '../../common/TagSelector'
const merge = require('deepmerge')

export default {
  components: {
    Editor,
    TagSelector,
  },

  data() {
    return {
      loadingSave: false,
      loadingAutomation: false,
      keywords: [],
      automation: null,
      constAutomation: null,
      webhook: null,
      type: 'create',
      editorScale: 1,
    }
  },

  computed: {
    user() {
      return this.$store.state.auth.user
    },

    isTemplate() {
      return this.user && this.user.business && this.user.business.is_template
    },
    contactFields() {
      return this.$store.getters["auth/contactFields"];
    },
  },

  mounted() {
    if (this.$route.params.id) {
      this.getAutomation()
      this.type = 'edit'
    } else if (this.$route.query.templateId) {
      this.getAutomationTemplate(this.$route.query.templateId)
    } else {
      this.automation = JSON.parse(JSON.stringify(this.$store.getters['automation/defaultAutomation']))
    }

    this.constAutomation = JSON.parse(JSON.stringify(this.automation))
  },

  methods: {
    getAutomationTemplate(id) {
      this.loadingAutomation = true
      this.$store.dispatch('automation/getTemplate', id)
        .then((data) => {
          this.automation = data
          this.automation.id = undefined
          this.automation.installs = []
          this.automation.tags = []
          this.loadingAutomation = false

          this.constAutomation = JSON.parse(JSON.stringify(this.automation))
        })
        .catch((err) => {
          this.loadingAutomation = false
        })
    },

    getAutomation() {
      this.loadingAutomation = true

      this.$store.dispatch('automation/get', this.$route.params.id)
        .then((res) => {
          this.loadingAutomation = false

          let actionsOriginal = JSON.parse(JSON.stringify(this.$store.getters['automation/actionList']))
    
          actionsOriginal.forEach((item) => {
            if (item.action === "CollectContactData") {
              this.contactFields.forEach((customItem) => {
                let format = customItem.type === 3 ? 'mm/dd' : ''
                item.options.collectFields.push({
                  name: customItem.id,
                  type: customItem.type,
                  format,
                  label: customItem.name,
                  enable: false,
                  message: `Please reply with your ${customItem.name}`
                });
              });
            }
          });

          let tmpAutomation = JSON.parse(JSON.stringify(res.data))
          let blocks = tmpAutomation.blocks
          blocks.forEach(block => {
            let orgBlock = actionsOriginal.find(item => item.action === block.action)
            if (orgBlock && orgBlock.options) {
              if (!block.options) {
                block.options = {}
              }
              if (block.options.collectFields) {
                let collectFields = orgBlock.options.collectFields

                collectFields.forEach((item1, index) => {
                  let field = block.options.collectFields.find(i => i.name === item1.name)
                  field = field || {}
                  collectFields[index] = merge.all([{},
                    item1,
                    field
                  ]);
                })
                block.options.collectFields = collectFields
              }
              block.options = merge.all([{},
              orgBlock.options,
              block.options,
              ])
            }
          });

          this.automation = tmpAutomation
          this.constAutomation = JSON.parse(JSON.stringify(this.automation))
        })
        .catch(() => {
          this.$router.push({ name: 'business.automation.index' })
          this.loadingAutomation = false
        })
    },

    submitAutomation() {
      if (this.automation.id) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      this.loadingSave = true

      this.$store.dispatch('automation/create', this.automation)
        .then((res) => {
          this.$store.dispatch("auth/getProfile");
          this.loadingSave = false
          if (res && res.data) {
            this.automation = JSON.parse(JSON.stringify(res.data))
            this.constAutomation = JSON.parse(JSON.stringify(res.data))
            this.$router.push({ name: 'business.automation.index' })
          }
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.automationForm.setErrors(err.response.data.errors)
          }
          this.loadingSave = false
        })
    },

    update() {
      this.loadingSave = true

      this.$store.dispatch('automation/update', {
        id: this.automation.id,
        data: this.automation
      })
        .then((res) => {
          this.loadingSave = false
          if (res && res.data) {
            this.automation = JSON.parse(JSON.stringify(res.data))
            this.constAutomation = JSON.parse(JSON.stringify(res.data))
          }
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.automationForm.setErrors(err.response.data.errors)
          }
          this.loadingSave = false
        })
    },

    zoomIn() {
      this.editorScale = this.editorScale * 1.1

    },

    zoomOut() {
      this.editorScale = this.editorScale * 0.9
    },

    onClickBack() {
      this.$router.push({ name: 'business.automation.index' })
    },

    difference(object, base) {
      function changes(object, base) {
        return _.transform(object, function (result, value, key) {
          if (base) {
            if (!_.isEqual(value, base[key])) {
              let tmp = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
              if (!_.isEmpty(tmp)) {
                result[key] = tmp
              }
            }
          } else {
            result[key] = value
          }
        });
      }
      return changes(object, base);
    }
  },

  beforeRouteLeave(to, from, next) {
    let diff = this.difference(this.automation, this.constAutomation);

    if (Object.keys(diff).length) {
      const answer = window.confirm('Are you sure you want to leave without saving?')
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }
}
</script>

<style lang="scss" scoped>
.selectedInstallWrapper {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 10px;

  li {
    padding: 3px 10px;
    color: #ffffff;
    background-color: $rb-blue;
    border-radius: 10px;
    margin: 5px;
    font-weight: bold;
  }
}

.list-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row;
  flex-wrap: wrap;

  .trigger-item {
    display: inline-block;
    text-align: center;
    margin-right: 30px;
    cursor: pointer;
    width: 96px;

    .icon {
      width: 96px;
      height: 96px;
      border: 2px solid #ced3e0;
      border-radius: 6px;
      margin-bottom: 9px;
      position: relative;
      text-align: center;
      line-height: 92px;
      color: #ced3e0;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 46px;
      }
    }

    .label {
      text-align: center;
      font-size: 15px;
      font-weight: 700;
      color: #4e5468;
    }

    &:hover,
    &.active {
      .icon {
        border-color: $rb-blue;
        color: $rb-blue;
      }
    }
  }
}

.checkbox-label {
  --primaryColor: #3578c6;
}

.custom-checkbox-wrapper .checkbox-label .checkbox-custom {
  border-color: $text-gray;
  top: 4px;
  height: 17px;
  width: 17px;
}

.custom-checkbox-wrapper .checkbox-label input:checked~.checkbox-custom::after {
  left: 5px;
  top: 0px;
}

.edit-header {
  height: 75px;
  display: flex;
  justify-content: space-between;

  .btn {
    outline: none;
    padding: 10px;

    i {
      font-size: 20px;
    }
  }
}
</style>

<style lang="scss">
.automation-item-page {
  .install-select {
    input {
      border: 2px solid $rb-blue;
    }
  }

  .vuestic-modal {
    zoom: var(--modal-scale)
  }

  .child-column {
    display: flex;

    .block-content-column {
      flex: 1 1 50%;
    }

    .condition-status {
      text-align: center;
      background-color: #1a76d2;
      color: #fff;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      border: 1px solid #9298ad;
      padding: 12px 12px;
      min-width: 145px;
      display: inline-block;
      font-weight: 700;
      border-radius: 5px;
      margin-bottom: 25px;
      position: relative;

      &.none {
        background-color: red;
        color: #FFF;
      }
      &:before {
        content: " ";
        width: 2px;
        top: -60px;
        height: 60px;
        background: #9298ad;
        display: block;
        position: absolute;
        left: calc(50% - 1px);
      }

      &:after {
        content: " ";
        width: 2px;
        height: 29px;
        background: #9298ad;
        display: block;
        position: absolute;
        bottom: -29px;
        left: calc(50% - 1px);
      }
    }
  }
}
</style>